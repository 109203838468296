<template>
  <div class="account-pages my-5 pt-5 text-left">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Recuperar Senha</h5>
                    <p>Informe seu e-mail.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img
                    src="@/assets/images/profile-img.png"
                    alt
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="card-body pt-3">
              <div class="p-2">
                <form class="form-horizontal">
                  <div class="form-group">
                    <label for="username">E-mail</label>
                    <input
                      type="text"
                      v-model="form.email"
                      class="form-control"
                      id="username"
                      placeholder="E-mail..."
                       :class="{ 'is-invalid': form && $v.form.email.$error }"
                    />
                  </div>

                  <div class="mt-3">
                    <button :disabled="sendbtn" @click.prevent="submit()" class="btn btn-primary btn-block" type="submit">
                      Enviar
                    </button>
                  </div>
                  <div class="mt-4 text-center">
                    <router-link
                      tag="a"
                      to="/login"
                      class="text-muted"
                    >
                    Voltar a Tela de Login
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
            <p class="text-center">
              © {{ new Date().getFullYear() }} LP10 Desenvolvimento Web
            </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import {
  required,
  email,
} from "vuelidate/lib/validators";
export default {
   data () {
    return {
      sendbtn: false,
      form:{
        email: null,
      }
    }
  },
  validations: {
    form: {
      email: { required, email },
    },
  },
  methods: {
    ...mapActions('auth', ['ActionDoLoginRecover']),
    submit(){
      this.$v.$touch()
      if(!this.$v.$invalid){
        this.submitRecover()
      }
    },
    async submitRecover(){
      try {
        this.sendbtn = true
        await this.ActionDoLoginRecover(this.form)
        this.sendbtn = false
        this.$swal({
          title: "Sucesso!",
          text: "Te enviamos um e-mail para redefinir sua senha de acesso!",
          icon: "success",
          confirmButtonText: "OK"
        }).then(()=>{
            this.$router.push({ name: 'login' })
        });
      } catch (err) {
        this.sendbtn = false
        this.$swal({
          title: "Erro ao recuperar senha!",
          text: err.body.message,
          icon: "error",
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },
  },
}
</script>